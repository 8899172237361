* {
    box-sizing: border-box;
}

body,html {

    width: 100vw;
    height: 100vh;
    margin: 0!important;
    padding: 0!important;
    text-align: justify;
    word-wrap: break-word;
    display: block;

    background-color: #fff;
    font-weight: 400;

    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    color: #0A1248;

    background-color: #f2f2f2 !important;
    font-family: "Pontiac", Arial, Helvetica, sans-serif;
}

header {
    
    padding: 0 40px;
}
header svg {
    inline-size: 24px;
    margin: auto 0;
}
header h1 {
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    gap: 7.5px;
}

main {
    padding: 0 40px;
}
header h1 {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}
main section {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}

.vertical-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.cards-items > * {
    width: 516px;
    min-height: 172px;
    box-shadow: rgb(0 0 0 / 20%) -1px 7.5px 7.5px 0px;
    padding: 40px;
    border-radius: 25px;
}
.cards-items > .icon {
    width: 32px;
    min-height: auto;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
}

form > label {
    width: 100%;
}
form > div {
    width: 100%;
}
form input {
    width: 100%;
    min-height: 32px;
    padding: 2.5px;
}

form button {
    margin-left: auto;
}
.status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 7.5px;
}
.status svg {
    width: 32px;
}

.icon svg {
    width: 32px;
    margin: 0 auto;
}


@media (max-width: 768px) {
    header {
        padding-left: 20px;
        padding-right: 20px;
    }
    main {
        padding: 0 20px;
    }
    .vertical-items > * {
        
        width: 100%;
    }
}


a, button, textarea, input
{
    outline: none;
    color: #0A1248;
    text-decoration: none;
    min-height: 32px;
    border-radius: 7.5px;
}

a, button
{
    font-weight: 400;
    overflow: hidden;
}
a:hover, button:hover
{
    color: lightblue;
}
a:focus, button:focus
{
    color: #49c500;
}
a > button
{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    
}

.gap20 {
    display: flex;
    gap: 20px;
}